import Vue from 'vue';
export default class OfertaService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }
    filtrarOfertas(filtros, nr_pagina, nr_titulos_pagina) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        filtros.apresentante = this._store.getters.cdApresentante;
        filtros.pagina = nr_pagina;
        filtros.titulos_por_pagina = nr_titulos_pagina;

        return Vue.http.get(
            url + '/apresentantes/api/apresentantes-oferta/',
            {
                params: filtros
            },
            {
                // headers: {
                //     Authorization: 'Bearer ' + this._store.getters.token
                // }
            }
        );
    }
    detalharOferta(oferta) {
        const url = process.env.VUE_APP_URL_BACK_OFFICE;
        return this._http.get(
            url + `/apresentantes/api/apresentantes-oferta/${oferta.id}/`,
            {
                params: {
                    cd_apresentante: this._store.getters.cdApresentante
                }
            }
        );
    }
    salvarOfertaApresentante(data) {
        var response = null;
        let url = process.env.VUE_APP_URL_BACK_OFFICE;
        url = url + '/apresentantes/api/apresentantes-oferta/';
        if (data.id) {
            let path = data.id + '/';
            response = Vue.http.put(url + path, data);
        } else {
            response = Vue.http.post(url, data);
        }
        return response;
    }

    buscarOfertaGlobal(params) {
        return this._http.get('protestado/oferta_global', {
            params: {
                oferta_id: params.id,
                valor_inicial: params.valor_inicial,
                valor_final: params.valor_final,
                oferta_ativa: params.status
            }
        });
    }

    exibirDetalheOfertaGlobal(oferta) {
        return this._http.get(`protestado/oferta_global?oferta_id=${oferta}`);
    }

    desabilitarOfertaGlobal(ofertaId) {
        return this._http.delete(
            `protestado/oferta_global?oferta_id=${ofertaId}`
        );
    }

    criarOfertaGlobal(oferta) {
        oferta.apresentante = this._store.getters.cdApresentante;
        let payload = {
            oferta: oferta
        };
        return this._http.post(`protestado/oferta_global`, payload);
    }

    buscarOfertaTitulo(params) {
        return this._http.get('protestado/oferta_titulo', {
            params: {
                oferta_id: params.id_oferta,
                id_titulo: params.numero_titulo,
                oferta_ativa: params.status
            }
        });
    }

    exibirDetalheOfertaTitulo(oferta) {
        return this._http.get(`protestado/oferta_titulo?oferta_id=${oferta}`);
    }

    buscarOfertasPorTitulo(id_titulo) {
        return this._http.get(
            `protestado/oferta_titulo?id_titulo=${id_titulo}`
        );
    }

    desabilitarOfertaTitulo(ofertaId) {
        return this._http.delete(
            `protestado/oferta_titulo?oferta_id=${ofertaId}`
        );
    }

    criarOfertaTitulo(oferta) {
        oferta.apresentante = this._store.getters.cdApresentante;
        let payload = {
            oferta: oferta
        };
        return this._http.post(`protestado/oferta_titulo`, payload);
    }

    consultaDetalhesTitulo(p_apresentante, p_titulo) {
        return this._http.get(`protestado/detalhe_titulo`, {
            params: {
                protestado_entidade_uuid: p_apresentante,
                id_titulo: p_titulo
            }
        });
    }

    buscarRenegociacoes(params) {
        return this._http.get(`protestado/renegociacoes`, {
            params: {
                status_pagamento: params.statusPagamento,
                forma_pagamento: params.formaPagamento,
                tipo_oferta: params.tipoOferta,
                data_inicio: params.data_inicio,
                data_fim: params.data_fim
            }
        });
    }

    consultaRelatorioOfertasGlobais(pagina, titulosPorPagina, filtros) {
        return this._http.get('protestado/relatorios/oferta-global', {
            params: {
                cd_apresentante: this._store.getters.cdApresentante,
                oferta_id: filtros.oferta_id,
                status_oferta: filtros.status_oferta,
                status_renegociacao: filtros.status_renegociacao,
                status_pagamento: filtros.status_pagamento,
                tipo_pagamento: filtros.tipo_pagamento,
                permanencia_inicio: filtros.permanencia_inicio,
                permanencia_fim: filtros.permanencia_fim,
                vencimento_inicio: filtros.vencimento_inicio,
                vencimento_fim: filtros.vencimento_fim,
                desconto_porcentagem_inicial:
                    filtros.desconto_porcentagem_inicial,
                desconto_porcentagem_final: filtros.desconto_porcentagem_final,
                desconto_valor_inicial: filtros.desconto_valor_inicial,
                desconto_valor_final: filtros.desconto_valor_final,
                pagina: pagina,
                titulos_por_pagina: titulosPorPagina
            }
        });
    }

    consultaRelatorioOfertasTitulo(pagina, titulosPorPagina, filtros) {
        return this._http.get('protestado/relatorios/oferta-titulo', {
            params: {
                cd_apresentante: this._store.getters.cdApresentante,
                dt_cadastro_de: filtros.dt_cadastro_de,
                dt_cadastro_ate: filtros.dt_cadastro_ate,
                numero_titulo: filtros.numero_titulo,
                desconto_valorado_de: filtros.desconto_valorado_de,
                desconto_valorado_ate: filtros.desconto_valorado_ate,
                desconto_porcentagem_de: filtros.desconto_porcentagem_de,
                desconto_porcentagem_ate: filtros.desconto_porcentagem_ate,
                oferta_renegociacao: filtros.oferta_renegociacao,
                oferta_status: filtros.oferta_status,
                titulo_status: filtros.titulo_status,
                formas_pagamento: filtros.formas_pagamento,
                ofertas_expiradas: filtros.ofertas_expiradas,
                pagina: pagina,
                titulos_por_pagina: titulosPorPagina
            }
        });
    }
}
